<template>
<the-header></the-header>
<div class="container">
    <b class="float" @click="formToggle">
        <router-link to="" >
            <h2 class="fa fa-plus my-float text-white"></h2>
        </router-link>
    </b>
    <div class="row">
        <search-action heading="Projects" @searchlist="getlist"></search-action>
    </div>
    
    <transition name="msg" mode="out-in">
    <strong class="text-success">{{msg}}</strong>
    </transition>
    <add-project v-if="formVisible" @close="formToggle" @load="getlist" :update="UpdateData" @msg="showMessgae"></add-project>
    <div class="py-2 table_div">
        <table class="table table-striped border">
        <thead>
            <tr>
            <th scope="col"></th>
            <th scope="col">Project Name</th>
            <th scope="col">Client Name</th>
            <th scope="col">Start Date</th>
            <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="getProjects.length==0"><td colspan="5" class="text-center">No Project Are Avaiable.</td>
            </tr>
           <tr v-else v-for="data in getProjects" :key="data.int_project_id">
            <th scope="row">{{data.int_project_id}}</th>
            <td>{{data.txt_project_name}}</td>
            <td>{{data.party_name}}</td>
            <td>{{data.dat_start}}</td>
            <td><base-button mode="icon" @click="updateProject(data.int_project_id)"><strong class="fas fa-edit" ></strong></base-button>
            <!--<base-button mode="icon" @click="DeleteProject(data.int_project_id)"><strong class="fas fa-trash" ></strong></base-button>-->
            </td>
            </tr>
            <tr style="text-align:right" >
                <td colspan="8" >
                    <ul class='pagination' >
                       <li class='page-item' :class="{active : item==getActive}" v-for="(item, index)  in getPagination" v-bind:key="index"><a href="#" class='page-link btn btn-sm' @click="pageredirect(item)" >{{getPaginationText[index]}}</a></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</div>
</template>
<script>

import AddProject from './AddProject.vue'
export default {
    components:{
        AddProject,
    },
    data(){
        return{
            formVisible:false,
            UpdateData:false,
            msg:null,
            search:null,
        }
    },methods:{
        pageredirect(id){
            this.getlist(id)
        },
        getlist(pn=1,option=null){
            this.$store.dispatch('project/getProject',{page_no:pn,option:option});
        },
        updateProject(id){
            this.formVisible=true;
            this.UpdateData=id;
        },
        formToggle(){
            this.UpdateData=false;
            this.formVisible=!this.formVisible
        },showMessgae(msg){
            this.msg=msg;
            setTimeout(()=>{
                this.msg=null;
            },3000);
        },searchlist(){
                this.getlist(1,this.search);
        },DeleteProject(id){
            var result = confirm('Are you Sure To Delete ');
            if(result){
                try{
                this.$store.dispatch('project/DeleteProject',{id:id});
                }catch(error){
                  console.log('error'+error)
                }
                setTimeout(()=>{
                    var msg = this.$store.getters['purchase/getDelMessage'];
                    this.showMessgae(msg);
                    this.getlist();
                },200);
            }
        }

    },
    computed:{
        getProjects(){
            return this.$store.getters['project/getProjects'];
        },
         getActive(){
            return this.$store.getters['project/getActive'];
        },
        getPagination(){
            return this.$store.getters['project/getpagination'];
        },
        getPaginationText(){
            return this.$store.getters['project/getpagination_text']
        }
    },
    created(){
        this.getlist();
    }
}
</script>
<style  scoped>
   
</style>