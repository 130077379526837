<template>
  <base-dialog :show="!!error" title="An Error" @close="handleError"
    ><p>{{ error }}</p></base-dialog
  >
  <base-dialog fixed :show="isLoading" title="Processing...">
    <base-spinner></base-spinner>
  </base-dialog>
  <form class="p-5 border">
    <fieldset>
      <legend v-if="!update">Add Project</legend>
      <legend v-else>Update Project</legend>
      <div class="row">
        <input type="hidden" name="id" id="id" v-model.trim="id">
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="project_name"> Name <span>*</span> </label>
          <input
            type="text"
            id="project_name"
            name="project_name"
            class="form-control form-control-sm"
            ref="project_name"
            :class="{ invalid: invalidName }"
            v-model.trim="project_name"
            @blur="validInput"
          />
          <span v-if="invalidName" :class="{ invalid: invalidName }"
            >Invalid Project Name</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="client_id"> Client <span>*</span></label>
          <!--<input type="text"
            id="client_id"
            v-focus="invalidOption"
            name="client_id"
            class="form-control form-control-sm"
            :class="{ invalid: invalidOption }" list="clients"
            v-model.trim="client_id"
            @blur="validInput">

          <datalist
            id="clients"
            @blur="validInput"
          >
          <option v-for="option in ClientOption"  :key="option.int_party_id" :value="option.txt_name"></option>
          </datalist>-->
          <select
            id="client_id"
            name="client_id"
            class="form-control form-control-sm"
            ref="client_id"
            :class="{ invalid: invalidOption }" list="clients"
            v-model.trim="client_id"
            @blur="validInput"
          >
          <option v-for="option in ClientOption"  :key="option.int_party_id" :value="option.int_party_id">{{option.txt_name}}</option>
          </select>
          <span
            v-if="invalidOption"
            :class="{ invalid: invalidOption }"
            >Choose Client</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="start_date"> Start Date</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            class="form-control form-control-sm"
            ref="start_date"
            v-model="start_date"
            :class="{ invalid: invalidStartDate }"
            @blur="validInput"
          />
          <span
            v-if="invalidStartDate"
            :class="{ invalid: invalidStartDate }"
            >Choose Start Date</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="end_date"> End Date</label>
          <input
            type="date"
            id="end_date"
            name="end_date"
            class="form-control form-control-sm"
            v-model="end_date"
          />
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-if="update">
          <base-button  @click.prevent="updateData" mode="btn"> Update </base-button >
          <base-button @click="close" mode="btn"> Close</base-button >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-else>
          <base-button @click="close" mode="btn"> Close</base-button >
          <base-button  @click.prevent="saveData(true)" mode="btn"> Save & Close </base-button >
          <base-button  @click.prevent="saveData" mode="btn"> Save & New </base-button >
        </div>
      </div>
    </fieldset>
  </form>
</template>
<script>
export default {
  emits: ["close", "load","msg"],
  props: ["update"],
  data() {
    return {
      id:null,
      project_name: null,
      client_id: null,
      end_date:null,
      start_date:null,
      isLoading: false,
      error: null,
      invalidName: false,
      invalidOption: false,
      valid: null,
      invalidStartDate:false,
      ClientOption:null,
    };
  },
  methods: {
    async saveData(selfclose = false) {
      let valid = null;
      this.validInput();
      if (
        this.invalidName ||
        this.invalidOption ||
        this.invalidStartDate 
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("project/addProject", {
            name: this.project_name,
            client_id:this.client_id,
            st_date:this.start_date,
            end_date:this.end_date,
          });
        } catch (error) {
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          if (selfclose == true) {
            this.$emit("close");
          }
          this.$emit("msg","Project Added Successfully")
          this.$emit("load");
          this.project_name = null;
          this.client_id = null;
          this.start_date = null;
          this.end_date = null;
        }, 2000);
      }
    },

    getdata() {
      if (this.update) {
        this.$store.dispatch("project/singleGetProject", { id: this.update });
        this.isLoading = true;
        setTimeout(() => {
          this.setData();
          this.isLoading=false;
        }, 2000);
      }
    },
    handleError() {
      this.error = null;
    },
    close() {
      this.$emit("close");
    },
    getOption(){
      var today=new Date();
      var date;
      if(today.getDate()<10){
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+0+today.getDate();
      }else{
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      }
      if(today.getMonth()<10){
        date=today.getFullYear()+'-'+0+(today.getMonth()+1)+'-'+today.getDate();
      }else{
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      }
      this.start_date=date;
      this.$store.dispatch("project/getOption", {id:0}); 
      this.isLoading=true;
      setTimeout(() => {
          this.setOption();
          this.isLoading=false;
        }, 2000); 
    },
    validInput() {
      if (!this.project_name) {
        
        this.$refs.project_name.focus();
        this.invalidName = true;
      }else if (!this.client_id) {
        this.invalidOption = true;
        this.$refs.client_id.focus();
      }else if (!this.start_date) {
        this.invalidStartDate = true;
        this.$refs.start_date.focus();
      }

      if (this.project_name) {
        this.invalidName = false;
      }
      if (this.client_id) {
        this.invalidOption = false;
      }
      if (this.start_date) {
        this.invalidStartDate = false;
      }
    },
    setOption(){
      this.ClientOption= this.$store.getters["project/getOption"];
    },
    setData() {
      const Data = this.$store.getters["project/getSingleProject"];
      this.id=Data[0].int_project_id ;
      this.project_name = Data[0].txt_project_name ;
      this.client_id = Data[0].int_party_id;
      this.start_date = Data[0].dat_start;
      this.end_date = Data[0].dat_end;
    },
    async updateData() {
      let valid = null;
      this.validInput();
      if (
        this.invalidName ||
        this.invalidOption ||
        this.invalidStartDate 
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("project/updateproject", {
            id:this.id,
            name: this.project_name,
            client_id:this.client_id,
            st_date:this.start_date,
            end_date:this.end_date,
          });
        } catch (error) {
          alert(error)
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          this.$emit("close");
          this.$emit("load");
          this.$emit("msg","Project Updated Successfully")
          this.project_name = null;
          this.client_id = null;
          this.start_date = null;
          this.end_date = null;
          this.id = null;
        }, 2000);
      }
    },

  },
  computed: {},
  created() {
    
    this.getdata();
    this.getOption();
  },
};
</script>
<style scoped>
label span {
  color: red;
}
.invalid {
  color: red;
}
select.invalid:focus,
select.invalid:active
input.invalid:focus,
input.invalid:active,
textarea.invalid:focus,
textarea.invalid:active,
textarea.invalid {
  outline: 2px solid red;
  border:1px solid red;
}
</style>
